import React from "react";
import styled from "styled-components";
import { navigate, useStaticQuery, graphql } from "gatsby";
import { useLocation } from "@reach/router";
import X from "../../images/x.svg";

const Voiceover = styled.li`
  margin-top: 0.5rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
`;

const Menu = styled.nav`
  background-color: var(--pink);
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 230px;
  transition: all 0.25s ease-in-out;
  opacity: 0;
  /* pointer-events: none; */
  display: flex;
  flex-direction: column;
  padding-right: 50px;
  padding-top: 25px;
  align-items: flex-end;
  justify-content: flex-start;
  z-index: -1;
  transform: translateX(230px);
  &.open {
    opacity: 1;
    z-index: 10000;
    transform: translateX(0px);
    position: fixed;
  }
  &.closed {
    right: 230px;
    z-index: -1;
    width: 0;
    overflow-x: hidden;
  }
  img {
    width: 48px;
    margin-bottom: 1rem;
    transform: translateX(12px);
    cursor: pointer;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0 0 0 50px;
    width: 100%;
    li {
      font-family: "Catamaran", sans-serif;
      font-size: 24px;
      text-align: right;
      font-weight: 300;
      padding-bottom: 0.75rem;
      a {
        color: #fff;
        font-family: "Catamaran", sans-serif;
        text-decoration: none;
        &.active {
          font-weight: 600;
        }
        &:hover {
          font-weight: 500;
        }
      }
    }
  }
`;

const PopoverMenu = ({ all_page_links, menuVisible, setMenuVisible }) => {
  const location = useLocation();

  const { pathname } = location;

  const data = useStaticQuery(graphql`
    query {
      allSanityArtist(filter: {tours: {elemMatch: {title: {ne: "null"}}}}) {
        edges {
          node {
            surname
            first_name
            tours {
              end_date(fromNow: true)
              start_date(fromNow: true)
            }
          }
        }
      }
      allSanityMenu {
        edges {
          node {
            links {
              title
              url
            }
          }
        }
      }
    }
  `);

  const menu_links = data.allSanityMenu.edges[0].node.links;

  const touring_artists = data.allSanityArtist.edges.filter((a) => {
    // Check if tours array exists and has at least one element
    if (a.node.tours && a.node.tours.length > 0) {
      // Check if end_date exists before calling includes
      const endDate = a.node.tours[0].end_date;
      return endDate && typeof endDate === 'string' && !endDate.includes('ago');
    }
    return false;
  }).length;

  const handleClick = (e, url) => {
    e.preventDefault();
    setMenuVisible(false);
    navigate(url);
  };

  return (
    <Menu className={menuVisible ? "open menu" : "closed menu"}>
      <div onClick={() => setMenuVisible(false)}>
        <img src={X} alt="close" />
      </div>
      <ul>
        {menu_links.map((m, index) => {
          const cn = m.url.replace("/", "");

          if (m.url === "/" && pathname === "/") {
            return (
              <li key={index}>
                <a
                  className="active"
                  href="#"
                  onClick={(e) => handleClick(e, m.url)}
                >
                  {m.title}
                </a>
              </li>
            );
          } else if (
            all_page_links.includes(m.url) ||
            all_page_links.includes(m.url + "/")
          ) {

          if(m.url === '/live') {

              if(touring_artists > 0) {
                return (
                  <li key={index}>
                    <a
                      className={
                        pathname.startsWith(m.url) && m.url !== "/"
                          ? `active ${cn}`
                          : cn
                      }
                      href="#"
                      onClick={(e) => handleClick(e, m.url)}
                    >
                      {m.title}
                    </a>
                  </li>
                );
              }

            } else {

              return (
                <li key={index}>
                  <a
                    className={
                      pathname.startsWith(m.url) && m.url !== "/"
                        ? `active ${cn}`
                        : cn
                    }
                    href="#"
                    onClick={(e) => handleClick(e, m.url)}
                  >
                    {m.title}
                  </a>
                </li>
              );


            }



          } else {
            return <></>;
          }
        })}
        <Voiceover>
          <a href="https://www.vobjmanagement.co.uk/">Voiceover</a>
        </Voiceover>
      </ul>
    </Menu>
  );
};

export default PopoverMenu;
