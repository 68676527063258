import React from "react";
import { AnimatePresence } from "framer-motion";


export function wrapPageElement({ element, props }) {
  return <AnimatePresence mode="wait">{element}</AnimatePresence>;
}

export function shouldUpdateScroll({
  routerProps: { location },
  getSavedScrollPosition,
}) {
  // const { pathname } = location
  // list of routes for the scroll-to-top-hook
  if (window !== undefined) {
    window.scrollTo(0, 0);
  }

  return false;
}
